import {
  extend,
  ValidationProvider,
  ValidationObserver
} from 'vee-validate/dist/vee-validate.full'

import { parsePhoneNumberFromString } from 'libphonenumber-js'

import { required, email, min, oneOf } from 'vee-validate/dist/rules'

import Vue from 'vue'
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)

extend('required', {
  validate(value) {
    var value_to_check = value
    if ( value && (typeof(value) == 'object') ) {
      if (typeof(value.value) != 'undefined') {
        value_to_check = value.value
      } else {
        value_to_check = value
        if (typeof(value.length) == 'number') {
          return {
            required: true,
            valid: value.length > 0
          };
        }
      }
    }
    return {
      required: true,
      valid: ['', null, undefined].indexOf(value_to_check) === -1
    };
  },
  message: (field, values) => {
    var fieldName = values['_field_'].replace('_', ' ')
    fieldName = fieldName.replace(fieldName[0], fieldName[0].toUpperCase())
    if (fieldName != '{field}') {
      if (Window.$i18n && Object.keys(Window.$i18n.messages).length > 0) {
        //return Window.$i18n.t('validations.messages.required', Window.$i18n.t(values['_field_']))

        return Window.$i18n.t('is_required', { '_field_':  Window.$i18n.t(field) })

        values._field_ = Window.$i18n.t(field)
        return Window.$i18n.t(`validations.messages.${values._rule_}`, values);
      }
      return fieldName + " is required"
    }
    if (Window.$i18n && Object.keys(Window.$i18n.messages).length > 0) {
      return Window.$i18n.t('required')
    }
    return 'Required'
  }
})

extend('ownZip', {
  params: [{ name: 'confirmation', isTarget: true }, { name: 'zips' } ],
  validate (value, { confirmation, zips }) {
    return (zips.includes(value) || confirmation)
  },
  message: (_, values) => {
    return 'The zip code "' + values['_value_'] + '" is not covered by your areas, please confirm'
  }
})

extend('min', {
  ...min,
  message: (_, values) => {
    var fieldName = values['_field_'].replace('_', ' ')
    fieldName = fieldName.replace(fieldName[0], fieldName[0].toUpperCase())
    return fieldName + " must be at least "+ values.length + " characters"
  }
})

extend('email', {
  ...email,
  message: (_, values) => {
    var fieldName = values['_field_'].replace('_', ' ')
    fieldName = fieldName.replace(fieldName[0], fieldName[0].toUpperCase())
    return fieldName + " must be a valid email"
  }
})

extend('phone', {
  validate (value) {
    const parsing = value ? parsePhoneNumberFromString(value) : null
    if (parsing) {
      return parsing.isValid()
    }
    return false
  },
  message: (_, values) => {
    var fieldName = values['_field_'].replace('_', ' ')
    fieldName = fieldName.replace(fieldName[0], fieldName[0].toUpperCase())
    return fieldName + " must be valid"
  }
})

extend('zipConfirmation', {
  validate: (value, { confirmed }) => {
    return true
  },
  message: 'You need to confirm the zip',
  params: [{ name: 'confirmed', isTarget: true }]
});
